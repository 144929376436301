import React, { useMemo, useState } from 'react';
import { Engineer, Metric } from '../context/Metrics';
import { InfoCircle, User } from 'iconoir-react';
import { NavArrowUp, NavArrowDown } from 'iconoir-react';

interface EngineerMetricsTableProps {
  availableEngineers: Engineer[];
  perEngineerMetrics: Metric[];
}

type SortConfig = {
    key: string;
    direction: 'asc' | 'desc';
  } | null;

  const TeamStatsTable: React.FC<EngineerMetricsTableProps> = ({ availableEngineers, perEngineerMetrics }) => {
    const [expanded, setExpanded] = useState(false);
    const [sortConfig, setSortConfig] = useState<SortConfig>(null);

    const uniqueMetricTitles = useMemo(() => {
      const titles = perEngineerMetrics.map(metric => metric.title);
      return Array.from(new Set(titles));
    }, [perEngineerMetrics]);

    const metricsByTitle = useMemo(() => {
      return perEngineerMetrics.reduce((acc, metric) => {
        acc[metric.title] = metric;
        return acc;
      }, {} as Record<string, Metric>);
    }, [perEngineerMetrics]);

    const formatMetricValue = (value: number, isPercentage: boolean): string => {
      if (isPercentage) {
        return `${value.toFixed(1)}%`;
      }
      if (value > 1000) {
        return `${(value / 1000).toFixed(1)}k`;
      }
      return value.toFixed(1);
    };

    const sortedEngineers = useMemo(() => {
      const engineers = [...availableEngineers];
      if (sortConfig) {
        engineers.sort((a, b) => {
          if (sortConfig.key === 'name') {
            const compareResult = a.name.localeCompare(b.name);
            return sortConfig.direction === 'asc' ? compareResult : -compareResult;
          } else {
            const metric = metricsByTitle[sortConfig.key];
            if (!metric) return 0;

            const valueA = metric.getValue('All repositories', a.name) as number;
            const valueB = metric.getValue('All repositories', b.name) as number;

            const compareResult = (valueA || 0) - (valueB || 0);
            return sortConfig.direction === 'asc' ? compareResult : -compareResult;
          }
        });
      }
      return engineers;
    }, [availableEngineers, sortConfig, metricsByTitle]);

    const handleSort = (key: string) => {
      setSortConfig(current => {
        if (current?.key === key) {
          if (current.direction === 'asc') {
            return { key, direction: 'desc' };
          }
          return null;
        }
        return { key, direction: 'asc' };
      });
    };

    const visibleEngineers = expanded ? sortedEngineers : sortedEngineers.slice(0, 3);

    const renderSortIcon = (title: string) => {
      if (sortConfig?.key !== title) return null;
      return sortConfig.direction === 'asc' ?
        <NavArrowUp className="inline w-4 h-4 ml-1" /> :
        <NavArrowDown className="inline w-4 h-4 ml-1" />;
    };

    return (
      <div className="bg-white rounded-lg shadow">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="pl-[72px] pr-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky left-0 bg-gray-50 cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSort('name')}
                >
                  <div className="flex items-center justify-between">
                    <span>Engineer</span>
                    <span className="w-4">{renderSortIcon('name')}</span>
                  </div>
                </th>
                {uniqueMetricTitles.map((title) => (
                  <th
                    key={title}
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                    onClick={() => handleSort(title)}
                  >
                    <div className="flex items-center justify-between">
                      <span>{title}</span>
                      <span className="w-4">{renderSortIcon(title)}</span>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {visibleEngineers.map((engineer) => (
                <tr key={engineer.name} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap sticky left-0 bg-white">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 h-8 w-8">
                        {engineer.avatarUrl ? (
                          <img
                            className="h-8 w-8 rounded-full"
                            src={engineer.avatarUrl}
                            alt={`${engineer.name}'s avatar`}
                          />
                        ) : (
                          <div className="h-8 w-8 rounded-full bg-gray-100 flex items-center justify-center">
                            <User className="h-5 w-5 text-gray-400" />
                          </div>
                        )}
                      </div>
                      <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                          {engineer.name}
                        </div>
                      </div>
                    </div>
                  </td>
                  {uniqueMetricTitles.map((title) => {
                    const metric = metricsByTitle[title];
                    if (!metric) {
                      return (
                        <td key={`${engineer.name}-${title}`} className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-400">N/A</div>
                        </td>
                      );
                    }

                    const value = metric.getValue('All repositories', engineer.name);
                    const change = metric.getChangePercentage('All repositories', engineer.name);
                    const displayValue = formatMetricValue(value as number, metric.isPercentage);
                    const showChange = Math.abs(change) > 0.01;
                    const changeDisplay = change.toFixed(1);
                    const changeColor = change > 0
                      ? metric.negative === 'up' ? 'text-red-600' : 'text-green-600'
                      : metric.negative === 'up' ? 'text-green-600' : 'text-red-600';

                    return (
                      <td key={`${engineer.name}-${title}`} className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">{displayValue}</div>
                        {showChange && (
                          <div className={`text-xs ${changeColor}`}>
                            {change > 0 ? '+' : ''}{changeDisplay}%
                          </div>
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {availableEngineers.length > 3 && (
          <div className="p-4 border-t border-gray-200">
            <button
              className="flex items-center text-gray-500 hover:text-gray-700"
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? (
                <>
                  <NavArrowUp className="mr-1" />
                  <span className="text-sm">Show less</span>
                </>
              ) : (
                <>
                  <NavArrowDown className="mr-1" />
                  <span className="text-sm">Show all {availableEngineers.length} engineers</span>
                </>
              )}
            </button>
            <div className="mt-4 flex items-center text-sm text-gray-500 italic">
                <InfoCircle className="w-4 h-4 text-gray-400 mr-1" />
                <p>Please note that only the time period filter applies to the team stats table above.</p>
            </div>
          </div>
        )}
      </div>
    );
  };

  export default TeamStatsTable;
