import React, { useState, useEffect } from 'react';
import RepositorySelector from '../components/RepositorySelector';
import TimePeriodSelector from '../components/TimePeriodSelector';
import { MetricCard } from '../components/MetricCard';
import { SuccessMeasureCard } from '../components/SuccessMeasureCard';
import { successMeasures } from '../sample_data/successMeasures';
import Toggle from '../components/Toggle';
import TaskDistributionTable from '../components/TaskDistributionTable';
import { useDirectionMetrics, useHealthMetrics, useMetrics, usePerEngineerMetrics, useVelocityMetrics } from '../context/Metrics';
import { UserSettings } from '../types/userSettings';
import TeamStatsTable from '../components/TeamStatsTable';

interface InsightsPageProps {
    userSettings: UserSettings | null;
}

const InsightsPage: React.FC<InsightsPageProps> = ({ userSettings }) => {
    const [isAutoPilotMode, setIsAutoPilotMode] = useState(userSettings?.autoPilot ?? false);

    useEffect(() => {
        if (userSettings?.autoPilot !== undefined) {
            setIsAutoPilotMode(userSettings.autoPilot);
        }
    }, [userSettings?.autoPilot]);

    const {
        loading,
        error,
        selectedRepo,
        setSelectedRepo,
        selectedPeriod,
        setSelectedPeriod,
        availableRepos,
        availableEngineers,
        timePeriods
    } = useMetrics();

    const { metrics: velocityMetrics } = useVelocityMetrics();
    const { metrics: healthMetrics } = useHealthMetrics();
    const { metrics: directionMetrics } = useDirectionMetrics();
    const { metrics: perEngineerMetrics } = usePerEngineerMetrics();

    if (loading) {
        return (
            <div className="p-6">
                <div className="flex items-center justify-center h-64">
                    <div className="text-lg">Loading metrics...</div>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="p-6">
                <div className="flex items-center justify-center h-64">
                    <div className="text-lg text-red-600">
                        Error loading metrics: {error.message}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="p-6">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-2xl font-semibold">Insights</h1>
                <div className="flex space-x-4">
                    <Toggle
                        label="Auto-pilot mode"
                        isOn={isAutoPilotMode}
                        onToggle={() => setIsAutoPilotMode(!isAutoPilotMode)}
                    />
                    <TimePeriodSelector
                        selectedPeriod={selectedPeriod}
                        onSelect={(period) => setSelectedPeriod(period)}
                        timePeriods={timePeriods}
                    />
                    <RepositorySelector
                        selectedRepo={selectedRepo}
                        onSelectRepo={setSelectedRepo}
                        repositories={availableRepos}
                    />
                </div>
            </div>
            <div className="mt-8">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {successMeasures.map((measure, index) => (
                        <SuccessMeasureCard
                            key={index}
                            title={measure.title}
                            description={measure.description}
                            type={measure.type as 'direction' | 'speed' | 'health'}
                            needsAttention={measure.needsAttention}
                            activeAutomations={measure.activeAutomations}
                        />
                    ))}
                </div>
            </div>
            <div className="mt-8">
                <h2 className="text-xl font-semibold mb-4">Shipping Velocity</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {velocityMetrics.map((metric, index) => (
                        <MetricCard
                            key={index}
                            title={metric.title}
                            value={metric.getValue(selectedRepo, null)}
                            changePercentage={metric.getChangePercentage(selectedRepo, null)}
                            isPercentage={metric.isPercentage}
                            isTimeSeries={metric.isTimeSeries}
                            negative={metric.negative}
                            timePeriod={selectedPeriod}
                            repository={selectedRepo}
                        />
                    ))}
                </div>
            </div>
            <div className="mt-8">
                <h2 className="text-xl font-semibold mb-4">Task Distribution</h2>
                <TaskDistributionTable metrics={directionMetrics}/>
            </div>
            <div className="mt-8">
                <h2 className="text-xl font-semibold mb-4">Organisational Health</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {healthMetrics.map((metric, index) => (
                        <MetricCard
                            key={index}
                            title={metric.title}
                            value={metric.getValue(selectedRepo, null)}
                            changePercentage={metric.getChangePercentage(selectedRepo, null)}
                            isPercentage={metric.isPercentage}
                            isTimeSeries={metric.isTimeSeries}
                            negative={metric.negative}
                            timePeriod={selectedPeriod}
                            repository={selectedRepo}
                        />
                    ))}
                </div>
            </div>
            {userSettings?.showMetricsByEngineer && (
                <div className="mt-8">
                    <h2 className="text-xl font-semibold mb-4">Team Stats</h2>
                    <TeamStatsTable
                        availableEngineers={availableEngineers}
                        perEngineerMetrics={perEngineerMetrics}
                    />
                </div>
            )}
        </div>
    );
};

export default InsightsPage;
